import { AmbientLight, Color, DirectionalLight, PerspectiveCamera, Scene, WebGLRenderer } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
export class SceneHelper {
    init(main, camPos = { x: 0, y: 0, z: 0 }, camLookAt = { x: 0, y: 0, z: 0 }) {
        this.scene = new Scene();
        this.scene.background = new Color(0xf0f0f0);
        this.camera = new PerspectiveCamera(36, this.canvasWidth / this.canvasHeight, 1, 1000);
        this.camera.position.set(camPos.x, camPos.y, camPos.z);
        this.camera.lookAt(camLookAt.x, camLookAt.y, camLookAt.z);
        this.scene.add(new AmbientLight(0xffffff, 0.5));
        const dirLight = new DirectionalLight(0xffffff, 1.1);
        dirLight.position.set(50, 100, 75);
        dirLight.castShadow = true;
        dirLight.shadow.camera.right = 2;
        dirLight.shadow.camera.left = -2;
        dirLight.shadow.camera.top = 2;
        dirLight.shadow.camera.bottom = -2;
        dirLight.shadow.mapSize.width = 1024;
        dirLight.shadow.mapSize.height = 1024;
        this.scene.add(dirLight);
        const lDirLight = dirLight.clone();
        lDirLight.position.set(-50, -100, -75);
        this.scene.add(lDirLight);
        // Renderer
        this.renderer = new WebGLRenderer({ antialias: true });
        // 		renderer.shadowMap.enabled = true;
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setClearColor(0x263238);
        main.appendChild(this.renderer.domElement);
        this.onWindowResize();
        // Controls
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.minDistance = 2;
        this.controls.maxDistance = 200;
        this.controls.update();
        this.controls.addEventListener("change", () => {
            this.animate();
        });
        return this.scene;
    }
    onWindowResize() {
        this.canvasWidth = window.innerWidth - 1;
        this.canvasHeight = window.innerHeight - 1;
        this.camera.aspect = this.canvasWidth / this.canvasHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.canvasWidth, this.canvasHeight);
        this.animate();
    }
    animate() {
        this.renderer.render(this.scene, this.camera);
    }
    moveCamera(x, y, z) {
        this.camera.position.set(x, y, z);
        this.animate();
        this.controls.update();
    }
}
