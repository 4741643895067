import { BoxGeometry, LineBasicMaterial, Matrix4, Mesh, Object3D, Raycaster, Scene } from "three";
import { MatekOneLoader, ModelIDs } from "./models";
export class CursorHelper {
    constructor(sceneHelper) {
        this.sceneHelper = sceneHelper;
        this.highlightCube = null;
        this._selBoxMargin = 0.2;
        this.rayCaster = new Raycaster();
    }
    clickEvent() {
        this.sceneHelper.scene.remove(this.clickCube);
        if (this.highlightCube !== null) {
            this.selectedCubeUuid = this.highlightCube.oInstance.uuid;
            this.clickCube = this.makeCubeForSelect(this.highlightCube.oInstance, this.highlightCube.oModel, this._selBoxMargin);
            this.clickCube.material.color.setHex(0x2aff00);
            this.sceneHelper.scene.add(this.clickCube);
        }
        this.sceneHelper.animate();
    }
    onPointerMove(event, oInstances) {
        this.pointer = {
            x: (event.clientX / window.innerWidth) * 2 - 1,
            y: -(event.clientY / window.innerHeight) * 2 + 1
        };
        this.rayCaster.setFromCamera(this.pointer, this.sceneHelper.camera);
        const intersects = this.rayCaster.intersectObjects(oInstances, true);
        if (intersects.length > 0) {
            let oInstance = intersects[0].object;
            while (oInstance.parent !== null && !(oInstance.parent instanceof Scene)) {
                oInstance = oInstance.parent;
            }
            this.highLight(oInstance);
        }
    }
    highLight(oInstance) {
        if (this.highlightCube !== null) {
            this.sceneHelper.scene.remove(this.highlightCube.hlMesh);
            this.highlightCube = null;
        }
        const oModel = MatekOneLoader.findModel(oInstance.name);
        this.highlightCube = {
            oInstance,
            oModel,
            hlMesh: this.makeCubeForSelect(oInstance, oModel, this._selBoxMargin)
        };
        this.sceneHelper.scene.add(this.highlightCube.hlMesh);
        this.sceneHelper.animate();
    }
    makeCubeForSelect(model, metaModel, margin) {
        const dimensions = metaModel.dimensions;
        const offset = metaModel.offset;
        const geometry = new BoxGeometry(dimensions.x + margin * 2, dimensions.y + margin * 2, dimensions.z + margin * 2);
        const material = new LineBasicMaterial({ color: 0x50ff00 });
        material.transparent = true;
        material.opacity = 0.5;
        const cube = new Mesh(geometry, material);
        cube.applyMatrix4(new Matrix4().makeTranslation(0, 1, 0));
        cube.position.set(model.position.x + (dimensions.x) / 2 + offset.x, model.position.y + (dimensions.y) / 2 + offset.y, model.position.z + (dimensions.z) / 2 + offset.z);
        return cube;
    }
}
