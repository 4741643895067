import { __awaiter } from "tslib";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
export var ModelIDs;
(function (ModelIDs) {
    ModelIDs["outerLeftLiving"] = "outer-left-living";
    ModelIDs["innerBed2"] = "inner-bed-2";
    ModelIDs["innerLiving"] = "inner-living";
    ModelIDs["walkwayOuterLeft"] = "walkway-outer-left";
    ModelIDs["walkwayOuterRight"] = "walkway-outer-right";
    ModelIDs["outerStairsLeft"] = "outer-stairs-left";
    ModelIDs["outerBed2"] = "outer-bed-2";
    ModelIDs["walkwayInnerSlimLR"] = "walkway-inner-slim-LR";
    ModelIDs["walkwayInnerWideLR"] = "walkway-inner-wide-LR";
    ModelIDs["roofLeftLiving"] = "roof-left-living";
    ModelIDs["env"] = "env";
})(ModelIDs || (ModelIDs = {}));
export class BuildingFactory {
    constructor(_models) {
        this._models = _models;
        this._building = {
            meta: {
                totalSqm: 0,
                numOfApartments: 0,
                pricePerSqm: 0,
                totalPrice: 0,
                expPricePerSqm: 0,
                expSellingTotal: 0,
                expMargin: 0,
                expROI: 0
            }, floors: []
        };
        for (let n = 0; n <= 3; n++) {
            const mi = this.placeModel(ModelIDs.outerLeftLiving, 0, 2.9 * n);
            this._building.floors.push({ models: [mi], yOffset: 2.9 * n });
        }
        this.recalcMeta();
    }
    placeModel(modelRef, x, yOffset) {
        console.log('placing', modelRef);
        const model = this._models.get(modelRef);
        const mi = {
            modelRef: modelRef,
            o3dInstance: model.object3d.clone(),
            pos: { x }
        };
        mi.o3dInstance.position.set(mi.pos.x, yOffset, mi.pos.z);
        mi.extras = [];
        if (model.extras) {
            model.extras.forEach(e => {
                mi.extras.push(this.placeModel(e, x, yOffset));
            });
        }
        return mi;
    }
    getObj3Ds() {
        return this._building.floors.map(floorPlan => floorPlan.models.map(m => [m.o3dInstance,
            m.extras.map(value => value.o3dInstance)
        ].flat()).flat()).flat();
    }
    push(modelRef, floorNr = 0) {
        const floorPlan0 = this._building.floors[floorNr];
        const lastMi = floorPlan0.models[floorPlan0.models.length - 1];
        const x = lastMi.pos.x + this._models.get(lastMi.modelRef).dimensions.x;
        const results = [];
        this._building.floors.forEach(floorPlan => {
            const mi = this.placeModel(modelRef, x, floorPlan.yOffset);
            floorPlan.models.push(mi);
            results.push(...([mi.o3dInstance,
                mi.extras.map(value => value.o3dInstance)
            ].flat()));
        });
        this.recalcMeta();
        console.log(results);
        return results;
    }
    pop() {
        const results = [];
        this._building.floors.forEach(floorPlan => {
            const mi = floorPlan.models.pop();
            results.push(...([mi.o3dInstance,
                mi.extras.map(value => value.o3dInstance)
            ].flat()));
        });
        this.recalcMeta();
        return results;
    }
    recalcMeta() {
        const floorPlan = this._building.floors[0];
        const floorCount = this._building.floors.length;
        const meta = floorPlan.models.reduce((p, c) => {
            const model = this._models.get(c.modelRef);
            p.expMargin += 1;
            p.numOfApartments += model.isMainModule ? floorCount : 0;
            p.totalSqm += model.sqm ? model.sqm * floorCount : 0;
            return p;
        }, {
            totalSqm: 0,
            numOfApartments: 0,
            pricePerSqm: 0,
            totalPrice: 0,
            expPricePerSqm: 0,
            expSellingTotal: 0,
            expMargin: 0,
            expROI: 0
        });
        meta.expROI = 50;
        meta.pricePerSqm = 1500;
        meta.expPricePerSqm = meta.pricePerSqm * (1 + meta.expROI / 100);
        meta.totalPrice = meta.pricePerSqm * meta.totalSqm;
        meta.expSellingTotal = meta.expPricePerSqm * meta.totalSqm;
        meta.expMargin = meta.expSellingTotal - meta.totalPrice;
        this._building.meta = meta;
    }
}
export class MatekOneLoader {
    constructor() {
        this._loader = new GLTFLoader();
        this._models = new Map();
    }
    // TODO :  implement some buffering
    static getModels() {
        return __awaiter(this, void 0, void 0, function* () {
            return MatekOneLoader._instance.loadAll();
        });
    }
    loadAll() {
        return __awaiter(this, void 0, void 0, function* () {
            const rawModelInput = yield fetch("models/data.json");
            let modelArray = yield rawModelInput.json();
            for (let ap of modelArray) {
                ap.object3d = yield this.loadGlb('models/' + ap.id + ".glb");
                ap.object3d.name = ap.id;
            }
            this._models = modelArray.reduce((map, model) => {
                map.set(model.id, model);
                return map;
            }, new Map());
            return this._models;
        });
    }
    static findModel(modelRef) {
        return this._instance._models.get(modelRef);
    }
    loadGlb(url, position = { x: 0, y: 0, z: 0 }, scale = { x: 1, y: 1, z: 1 }) {
        return new Promise((resolve, reject) => {
            this._loader.load(url, function (gltf) {
                const o = gltf.scene.children[0];
                o.position.set(position.x, position.y, position.z);
                o.scale.set(scale.x, scale.y, scale.z);
                resolve(o);
            }, undefined, reject);
        });
    }
}
MatekOneLoader._instance = new MatekOneLoader();
