<script lang="ts">
    import {onMount} from "svelte";
    import type {Building, Model} from "./models";
    import {BuildingFactory, MatekOneLoader, ModelIDs} from "./models";
    import {SceneHelper} from "./scene-helper";
    import {fromEvent, interval, throttle} from "rxjs";
    import {CursorHelper} from "./cursor-helper";
    import {Object3D} from "three";

    let main, scene;
    let models: Map<ModelIDs, Model>;
    let sceneHelper: SceneHelper = new SceneHelper();
    let cursorHelper;
    let modelInstances = [];
    let building: Building;
    let buildingMeta;

    let buildingFactory: BuildingFactory;

    // basic configuration
    let camPos = {x: 50, y: 1.5, z: 50};
    let camLookAt = {x: 0, y: 0, z: 0};
    const selBoxMargin = 0.2;
    let compModMap;

    // Create our number formatter.
    let formatEur = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0,
    });

    onMount(() => {
        init();
        cursorHelper = new CursorHelper(sceneHelper);
        const clicks = fromEvent(main, 'pointermove');
        const result = clicks.pipe(throttle(() => interval(100)));
        result.subscribe(x => cursorHelper.onPointerMove(x, modelInstances));
    });


    async function init() {
        scene = sceneHelper.init(main, camPos, camLookAt);
        models = await MatekOneLoader.getModels();
        buildingFactory = new BuildingFactory(models);
        buildingMeta = buildingFactory._building.meta;
        modelInstances = buildingFactory.getObj3Ds();
        scene.add(...modelInstances);
        sceneHelper.animate();
    }

    function checkIfCanAddModule(uuid: string):boolean{
        return buildingFactory._building.floors[0]
            .models.filter(mi => models.get(mi.modelRef).isModule)
            .pop().o3dInstance.uuid === uuid;
    }

    function clickEventHelper() {
        if (cursorHelper.highlightCube?.oInstance.name) {
            compModMap = models.get(cursorHelper.highlightCube.oInstance.name).compatibles;
            if (compModMap) {
                cursorHelper.clickEvent();
            }
            checkIfCanAddModule(cursorHelper.selectedCubeUuid) ? openNav() : closeNav();
        }
    }

    function selectedModule(moduleRef: ModelIDs) {
        const os: Object3D[] = buildingFactory.push(moduleRef);
        modelInstances.push(...os);
        scene.add(...os);
        refresh(os[0]);
    }

    function deleteModule(){
        const os: Object3D[] = buildingFactory.pop();
        modelInstances = modelInstances.filter(mi=>os.findIndex(o => o===mi)<0);
        scene.remove(...os);
        const o = buildingFactory._building.floors[0].models[buildingFactory._building.floors[0].models.length-1];
        refresh(o.o3dInstance)
    }

    function refresh(o: Object3D) {
        cursorHelper.highLight(o);
        cursorHelper.clickEvent();
        compModMap = models.get(cursorHelper.highlightCube.oInstance.name).compatibles;
        buildingMeta = buildingFactory._building.meta;
        sceneHelper.animate();
    }


    function openNav() {
        document.getElementById("mySidenav").style.display = "block";
    }

    function closeNav() {
        document.getElementById("mySidenav").style.display = "none";
        cursorHelper.clickEvent()
    }

</script>

<main>
    <div id="mySidenav" class="sidenav" style="left:0;">
        <a class="closeBtn" on:click={()=>closeNav()}>&times;</a>
        {#if compModMap}
            {#each compModMap.W as module}
                <button on:click={()=>selectedModule(module)} type="button" class="">Add {models.get(module).name}</button>
            {/each}
        {/if}
        {#if buildingFactory && buildingFactory._building.floors[0].models.length>1}
            <button on:click={()=>deleteModule()} type="button" class="deletebtn">Delete</button>
        {/if}
    </div>
    <div id="prices" class="sidenav" style="right:0; display: block">
        {#if buildingMeta}
        <h2>House</h2>
        <h1>{buildingMeta.totalSqm} m2</h1>

        <h2>Number of apartments</h2>
        <h1>{buildingMeta.numOfApartments}</h1>

        <h2>Price per m2</h2>
        <h1>{ formatEur.format(buildingMeta.pricePerSqm)} </h1>

        <h2>Price of the house</h2>
        <h1>{formatEur.format(buildingMeta.totalPrice)} </h1>

        <h2>Expected selling price per m2</h2>
        <h1>{formatEur.format(buildingMeta.expPricePerSqm)} </h1>

        <h2>Expected selling price of the house</h2>
        <h1>{formatEur.format(buildingMeta.expSellingTotal)} </h1>

        <h2>Expected margin </h2>
        <h1>{formatEur.format(buildingMeta.expMargin)} </h1>

        <h2>Expected ROI</h2>
        <h1>{buildingMeta.expROI} %</h1>
        {/if}
    </div>
    <div bind:this={main} id="bg" on:click={() =>clickEventHelper()}></div>
</main>

<style>
    #prices h1 {
        margin:0 0.2rem 1rem 0.2rem;
        color: #04AA6D;
        padding: 0;
    }
    #prices h2 {
        font-size: 1rem;
        margin:0;
        padding: 0;
    }

    #bg {
        position: absolute;
        top: 0;
        left: 0;
    }

    main {
        text-align: center;
        padding: 1em;
        max-width: 240px;
        margin: 0 auto;
    }

    .sidenav {
        height: 100%;
        width: 250px;
        display: none;
        position: fixed;
        z-index: 1;
        top: 0;
        background-color: #dadada;
        overflow-x: hidden;
        padding-top: 60px;
    }


    .sidenav a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: #818181;
        display: block;
    }

    .sidenav a:hover {
        color: #010101;
    }
    button {
        background-color: #04AA6D;
        color: white;
        padding: 14px 20px;
        margin: 8px 0;
        border: none;
        cursor: pointer;
        width: 100%;
        opacity: 0.9;
    }
    button:hover {
        opacity:1;
    }
    .deletebtn {
        float: left;
        width: 100%;
    }


    .deletebtn {
        background-color: #f44336;
    }
    .sidenav .closeBtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
    }

    @media screen and (max-height: 450px) {
        .sidenav {
            padding-top: 15px;
        }
        .deletebtn {
            width: 100%;
        }

        .sidenav a {
            font-size: 18px;
        }
    }
</style>
